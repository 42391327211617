import {GetAllIntegrations} from "@/service/apiService/IntegrationService";
import {IIntegrationDataModel} from "@/Interfaces/DataModel/IntegrationDataModel";
import {SimplifiedComponent} from "@/Interfaces/DataModel/MediaPostDataModel";

export async function loadIntergationPages(sellerId: string) {
    const resp = await GetAllIntegrations(sellerId);

    const components = resp.result;
    const modofiedGroup = groupByIntegrationName(components)

    return modofiedGroup
}

type GroupedComponents = {
    [key: string]: SimplifiedComponent[];
};

function groupByIntegrationName(components: IIntegrationDataModel[]): GroupedComponents {
    return components.reduce((acc: GroupedComponents, component: IIntegrationDataModel) => {
        const {integrationName, componentId, componentName} = component;


        if (!acc[integrationName]) {
            acc[integrationName] = [];
        }


        acc[integrationName].push({
            label: componentName,
            value: componentName
        });


        return acc;
    }, {});
}

export function convertTODataModel(dataModel) {
    return dataModel
}