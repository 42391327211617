require('dotenv').config();
const signalR = {
    baseUrl: process.env.signalR_base_url || 'https://api-coms-buyconn-com',
    orders: {
        url: process.env.signalR_base_url+'/orderhub',
        invoke: {
            joinOrderStream: 'JoinOrderStreaming',
            updateOrder: 'OrderUpdateEvent'
        },
        listen: {
            newOrder: 'onOrderDashboardUIEvent'
        }
    },
    chat: {
        url: process.env.signalR_base_url+'/chathub',
        invoke: {
            createAGroup: 'CreateGroup',
            joinAGroup: 'JoinGroup',
            rejoinInGroups: 'ReJoinAllGroups',
            sendMessage: 'SendMessage'
        },
        listen: {
            receiveMessage: 'OnMessage'
        }
    }
}


export const config = {
    BaseUrl: process.env.seller_api_base_url || 'https://api-seller-buyconn-com/api/v1',
    CoreSiloBaseUrl: 'http://api-core-buyconn-com:5000/api/v1/core/',
    mediafileUrl:process.env.customerapi_base_url || 'api-shop.buyconn.com',
    Origin: 'https://sell.buyconn.com',
    signalR,
    OpenPinCodeService: 'https://api.postalpincode.in/pincode',
    OpenStreetMapReverseAddressLookUp: 'https://nominatim.openstreetmap.org/reverse',
    customerSide:process.env.buyer_Api_base_url || 'https://api-shop.buyconn.com/api/v1',
    proxyValue:process.env.buyer_Api_base_url || 'https://api-shop.buyconn.com/api/v1',
}
