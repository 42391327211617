import {ICommentsViewModel} from "@/Interfaces/DataModel/CommentsDataModel";
import {ViewModelStatus} from "@/config/dataConfig";

export function EmptyInitActiveCommentViewModel(regionId,sellerId,userId):ICommentsViewModel{
    const emptyModel:ICommentsViewModel={
        regionId,
        sellerId,
        userId,
        commentId:"",
        lastUpdateTimeStamp:`${new Date().toISOString()}`,
        createdBy:"",
        createdOn:`${new Date().toISOString()}`,
        comment:"",
        parentCommentId:"",
        commentType:"",
        sourceId:"",
        sourceType:"",
        actions:[],
        events:{

        },
        triggers:{

        },
        tags:[],
        saved:ViewModelStatus.New
    }
    return emptyModel;
}

export function queryForGetComments(){
    const emptyModel={
        sourceId:"",
        sourceType:""
    }
    return emptyModel
}