<template>
  <div v-if="visible" class="modal mt-4">
    <div class="modal-overlay" @click="close"></div>

    <div
        class="modal-content scrollable"
        :style="{ backgroundColor: isDarkMode ? '#181733' : '#fff' }"
    >
      <ArgonButton
          class="close-button"
          :variant="isDarkMode ? 'outline' : 'outline'"
          :color="isDarkMode ? 'white' : 'primary'"
          @click="close"
      >Close</ArgonButton>

      <div class="modal-header">
        <label class="text-md">Latest Activities</label>
        <div class="tabs">
          <ArgonButton
              :class="isDarkMode ? 'text-white' : 'text-primary'"
              :variant="isDarkMode ? 'outline' : 'fill'"
              class="ms-2"
              color="white"
              v-for="tab in tabs"
              :key="tab.name"
              @click="setActiveTab(tab.name)"
          >
            {{ tab.label }}
          </ArgonButton>
        </div>
      </div>

      <div class="modal-body scrollable">
        <div v-if="activeTabComponent === 'Comments'">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, defineProps, defineEmits } from "vue";
import { useStore } from "vuex";
import ArgonButton from "@/components/ArgonButton.vue";

const props = defineProps({
  visible: Boolean,
});

const emit = defineEmits(["cardCommentscloseModal"]);
const store = useStore();

const isDarkMode = computed(() => store.state.argon.darkMode);
const close = () => emit("cardCommentscloseModal", false);


const activeTab = ref("Comments");
const tabs = ref([
  { name: "Comments", label: "Comments" },
  { name: "Recent Activity", label: "Recent Activity" },
]);

const setActiveTab = (tabName) => {
  activeTab.value = tabName;
};

const activeTabComponent = computed(() => {
  return activeTab.value;
});
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
}
.modal-overlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.modal-content {
  padding: 20px;
  border-radius: 4px;
  position: relative;
  z-index: 1001;
  width: 50%;
  margin-right: 20px;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}
.modal-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  margin-bottom: 20px;
}
.tabs {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
}
.tabs button {
  margin-right: 10px;
  padding: 5px 10px; /* Adjust padding as needed */
  cursor: pointer;
}
.scrollable {
  max-height: 900px; /* Set the desired max height */
  overflow-y: auto;  /* Enable vertical scrolling */
}
</style>
