enum StoreDataModelKeys {
    category = 'category',
    city = 'city',
    contactNumber = 'contactNumber',
    country = 'country',
    district = 'district',
    email = 'email',
    geoCode = 'geoCode',
    pinCode = 'pinCode',
    primaryCategory = 'primaryCategory',
    secondaryPhoneNumber = 'secondaryPhoneNumber',
    sections = 'sections',
    state = 'state',
    storeAddress = 'storeAddress',
    storeImage = 'storeImage',
    storeManagerName = 'storeManagerName',
    storeName = 'storeName',
    websiteUrl = 'websiteUrl',
    tags = 'tags',
    sellerId = 'sellerId',
    storeId = 'storeId',
    userId = 'userId',
    regionId = 'regionId',
    gstNumber = 'gstNumber',
    deliveryType = 'deliveryType',
    status = 'status',
    deliveryViewModel = "deliveryViewModel",
    visibleToPublic = "visibleToPublic"
}


interface IStoreDataModel {
    [StoreDataModelKeys.category]: string[];
    [StoreDataModelKeys.city]: string;
    [StoreDataModelKeys.contactNumber]: string;
    [StoreDataModelKeys.country]: string;
    [StoreDataModelKeys.district]: string;
    [StoreDataModelKeys.email]: string;
    [StoreDataModelKeys.geoCode]: string;
    [StoreDataModelKeys.pinCode]: string;
    [StoreDataModelKeys.primaryCategory]: string;
    [StoreDataModelKeys.secondaryPhoneNumber]: string;
    [StoreDataModelKeys.sections]: string[];
    [StoreDataModelKeys.state]: string;
    [StoreDataModelKeys.storeAddress]: string;
    [StoreDataModelKeys.storeImage]: string;
    [StoreDataModelKeys.storeManagerName]: string;
    [StoreDataModelKeys.storeName]: string;
    [StoreDataModelKeys.websiteUrl]: string;
    [StoreDataModelKeys.tags]: string[];
    [StoreDataModelKeys.sellerId]: string;
    [StoreDataModelKeys.storeId]: string;
    [StoreDataModelKeys.userId]: string;
    [StoreDataModelKeys.regionId]: string;
    [StoreDataModelKeys.gstNumber]: string;
    [StoreDataModelKeys.deliveryType]: string[];
}

interface IStoreActiveViewModel {
    [StoreDataModelKeys.category]: string[];
    [StoreDataModelKeys.city]: string;
    [StoreDataModelKeys.contactNumber]: string;
    [StoreDataModelKeys.country]: string;
    [StoreDataModelKeys.district]: string;
    [StoreDataModelKeys.email]: string;
    [StoreDataModelKeys.geoCode]: string;
    [StoreDataModelKeys.pinCode]: string;
    [StoreDataModelKeys.primaryCategory]: string;
    [StoreDataModelKeys.secondaryPhoneNumber]: string;
    [StoreDataModelKeys.sections]: string[];
    [StoreDataModelKeys.state]: string;
    [StoreDataModelKeys.storeAddress]: string;
    [StoreDataModelKeys.storeImage]: string;
    [StoreDataModelKeys.storeManagerName]: string;
    [StoreDataModelKeys.storeName]: string;
    [StoreDataModelKeys.websiteUrl]: string;
    [StoreDataModelKeys.tags]: string[];
    [StoreDataModelKeys.sellerId]: string;
    [StoreDataModelKeys.storeId]: string;
    [StoreDataModelKeys.userId]: string;
    [StoreDataModelKeys.regionId]: string;
    [StoreDataModelKeys.status]: string;
    [StoreDataModelKeys.gstNumber]: string;
    [StoreDataModelKeys.deliveryType]: string[];
    [StoreDataModelKeys.deliveryViewModel]: string
}

interface IStoreListViewModel {
    storeName: String,
    storeId: String
}

export {IStoreActiveViewModel, IStoreDataModel, StoreDataModelKeys, IStoreListViewModel};
