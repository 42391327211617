<!-- Modal.vue -->
<template>
  <div v-if="visible" class="modal">
    <div class="modal-overlay" @click="close"></div>

    <div
      class="modal-content"
      :style="{ backgroundColor: isDarkMode ? '#181733' : '#fff' }"
    >
      <ArgonButton
        class="close-button"
        :variant="isDarkMode ? 'outline' : 'outline'"
        :color="isDarkMode ? 'white' : 'primary'"
        @click="close"
        >Close</ArgonButton
      >
      <div class="modal-header">
        <label class="text-md">Enquiry Pipeline Update</label>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps } from "vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { useStore } from "vuex";
const store = useStore();
const isDarkMode = computed(() => store.state.argon.darkMode);
const props = defineProps({
  visible: Boolean,
  customerEnquirySelected: Boolean,
});
const emit = defineEmits(["closeWorkFlowModal"]);

const close = () => {
  emit("closeWorkFlowModal", false);
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
}
.modal-overlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.modal-content {
  padding: 20px;
  border-radius: 4px;
  position: relative;
  z-index: 1001;
  width: 50%;
  margin-right: 20px;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}
.modal-header {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
</style>
