import { IResponseDataModel } from "@/Interfaces/DataModel/ResponseDataModel";
import { IWorkFlowCardActionDataModel } from "@/Interfaces/DataModel/workFlowCardActionDataModel";
import { WorkFlowCardsActions } from "@/service/Common/ApiRoutes";
import ApiService  from "@/service/Common/ApiService";
import ApiCoreSiloService  from "@/service/Common/CoreApiService";
import axios      from "axios";

function createWorkFlowCards(sellerId:string,workflowId:string,data:IWorkFlowCardActionDataModel):Promise<IResponseDataModel<IWorkFlowCardActionDataModel>>{
    const {url,method}:{url:string,method:string} = WorkFlowCardsActions.CreateAcard(sellerId,workflowId);
    return ApiService.SendRequest(url, method, {data});
}
function updateWorkFlowCards(sellerId:string,workflowId:string,data:IWorkFlowCardActionDataModel):Promise<IResponseDataModel<IWorkFlowCardActionDataModel>>{
    const {url,method}:{url:string,method:string} = WorkFlowCardsActions.UpdateAcard(sellerId,workflowId,data.cardId);
    return ApiService.SendRequest(url, method, {data});
}
function getAWorkFlowCards(sellerId:string,workflowId:string,cardId:string):Promise<IResponseDataModel<IWorkFlowCardActionDataModel>>{
    const {url,method}:{url:string,method:string} = WorkFlowCardsActions.getACard(sellerId,workflowId,cardId);
    return ApiService.SendRequest(url, method, );
}
function getAllWorkFlowCards(sellerId:string,workflowId:string):Promise<IResponseDataModel<IWorkFlowCardActionDataModel>>{
    const {url,method}:{url:string,method:string} = WorkFlowCardsActions.getAllCards(sellerId,workflowId);
    return ApiService.SendRequest(url, method, {query:{page:0}});
}
function removeAWorkFlowCards(sellerId:string,workflowId:string,cardId:string):Promise<IResponseDataModel<IWorkFlowCardActionDataModel>>{
    const {url,method}:{url:string,method:string} = WorkFlowCardsActions.deleteACard(sellerId,workflowId,cardId);
    return ApiService.SendRequest(url, method, );
}
async function cardTemplate():Promise<any>{
    const {url,method}:{url:string,method:string} = WorkFlowCardsActions.downloadTemplateUrl()
    const response:any = await axios({ url, method });
    return response
}
export {createWorkFlowCards,updateWorkFlowCards,getAWorkFlowCards,getAllWorkFlowCards,removeAWorkFlowCards,cardTemplate}