import {IWorkFlowCardActionDataModel,IWorkFlowCardActionViewModel} from "@/Interfaces/DataModel/workFlowCardActionDataModel";

export  function EmptyInitActiveWorkFlowCardActionViewModel(sellerId,regionId,userId){
    const emptyModel={
        regionId: "",
        sellerId: "",
        storeId: "",
        userId: "",
        cardId: "",
        cardType: "",
        state: "",
        stageId: "",
        workflowId: "",
        currentAssignee: "",
        description: "",
        participants: [
        ""
    ],
        actions: [
        ""
    ],
        pinnedComments: [
        ""
    ],
        currentStageId: "",
        currentStageName: "",
        totalEffortInHours: 0,
        remainingEffortInHours: 0,
        effortInHours: 0,
        points: 0,
        priority: "",
        areaPath: "",
        iterationStepCount: 0,
        events: {},
        triggers: {},
        tags: [],
        metadata: {}

    }
}

