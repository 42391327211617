<!-- Modal.vue -->
<template>
  <div v-if="visible" class="modal">
    <div class="modal-overlay" @click="close"></div>
    <div class="modal-content" :style="{ backgroundColor: isDarkMode ? '#181733' : '#fff' }">

      <ArgonButton
          :color="isDarkMode ? 'white' : 'primary'"
          :variant="isDarkMode ? 'outline' : 'outline'"
          class="close-button"
          @click="close"
      >close
      </ArgonButton
      >
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import {computed, defineEmits, defineProps} from "vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { useStore } from "vuex";
const store = useStore();
const isDarkMode = computed(() => store.state.argon.darkMode);
const props = defineProps({
  visible: Boolean,
});
const emit = defineEmits(["closeModal"]);

const close = () => {
  emit("cardEnquirycloseModal", false);
};

</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;

  height: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 4px;
  position: relative;
  z-index: 1001;
  width: 50%;
  margin-right: 20px;

}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
