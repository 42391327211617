import { IUserDataModel } from "@/Interfaces/DataModel/UserDataModel";
import ApiService from "../Common/ApiService";
import { UserApi } from "../Common/ApiRoutes";
import { IResponseDataModel } from "@/Interfaces/DataModel/ResponseDataModel";

function CreateAUser(sellerId: string, data: IUserDataModel): Promise<IResponseDataModel<IUserDataModel>> {
    const { url, method }: { url: string, method: string } = UserApi.CreateAUser(sellerId)
    return ApiService.SendRequest(url, method, { data })

}
function GetAllUsers(sellerId: string): Promise<IResponseDataModel<IUserDataModel[]>> {
    const { url, method }: { url: string, method: string } = UserApi.GetAllUsers(sellerId)
    return ApiService.SendRequest(url, method)

}
function GetAUser(sellerId: string, userId: string): Promise<IResponseDataModel<IUserDataModel>> {
    const { url, method }: { url: string, method: string } = UserApi.GetAUser(sellerId, userId)
    return ApiService.SendRequest(url, method)

}
function UpdateAUser(sellerId: string, userId: string, data: IUserDataModel): Promise<IResponseDataModel<IUserDataModel>> {
    const { url, method }: { url: string, method: string } = UserApi.UpdateAUser(sellerId, userId)
    return ApiService.SendRequest(url, method, { data })

}
function DeleteAUser(sellerId: string, userId: string): Promise<IResponseDataModel<IUserDataModel>> {
    const { url, method }: { url: string, method: string } = UserApi.DeleteAUser(sellerId, userId)
    return ApiService.SendRequest(url, method)

}
function UserCount(sellerId: string): Promise<IResponseDataModel<number>> {
    const { url, method }: { url: string, method: string } = UserApi.UserCount(sellerId)
    return ApiService.SendRequest(url, method)

}

function userPermissionUpdate(sellerId:string,userId:string,data:IUserDataModel): Promise<IResponseDataModel<IUserDataModel>> {
    const {url,method} :{url:string,method:string} = UserApi.UserPermissionUpdate(sellerId,userId)
    return ApiService.SendRequest(url,method,{data})
}
export {
    CreateAUser,
    GetAUser,
    GetAllUsers, UpdateAUser,
    DeleteAUser,
    UserCount,
    userPermissionUpdate
}