<template>
  <div id="sidenav-collapse-main" class="collapse navbar-collapse w-auto h-auto h-100">
    <ul class="navbar-nav">
      <li class="nav-item">
        <!--  -->


        <sidenav-collapse collapse-ref="dashboardsExamples" nav-text="Dashboard"
                          :class="getRoute() === 'dashboards' ? 'active' : ''">
          <template #icon>
            <i class="ni ni-umbrella-13 text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-item :to="{ name: 'DashboardOverView' }" mini-icon="R" text="OverView" />
            </ul>
          </template>
        </sidenav-collapse>
        <sidenav-collapse collapse-ref="dashboardsExamples" nav-text="Customer Desk"
          :class="getRoute() === 'dashboards' ? 'active' : ''">
          <template #icon>
            <i class="ni ni-settings text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->

              <sidenav-item :to="{ name: 'CustomerProfile' }" mini-icon="C" text="Customer Profiles" />
              <sidenav-item :to="{ name: 'EnquiryManagement' }" mini-icon="E" text="Enquiry" />
            </ul>
          </template>
        </sidenav-collapse>

        <sidenav-collapse collapse-ref="dashboardsExamples" nav-text="Analytics"
          :class="getRoute() === 'dashboards' ? 'active' : ''">
          <template #icon>
            <i class="ni ni-chart-pie-35 text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Reports' }" mini-icon="R" text="Reports" />
            </ul>
          </template>
        </sidenav-collapse>

        <sidenav-collapse collapse-ref="dashboardsExamples" nav-text="Workflows"
          :class="getRoute() === 'dashboards' ? 'active' : ''">
          <template #icon>
            <i class="ni ni-delivery-fast text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'OrderDashboard' }" mini-icon="O" text="Order Dashboard" />
              <sidenav-item :to="{ name: 'PastOrder' }" mini-icon="P" text="Past Orders" />
            </ul>
          </template>
        </sidenav-collapse>

        <sidenav-collapse collapse-ref="dashboardsExamples" nav-text="Inventory"
          :class="getRoute() === 'dashboards' ? 'active' : ''">
          <template #icon>
            <i class="ni ni-archive-2 text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Coupons' }" mini-icon="C" text="Coupons" />
              <sidenav-item :to="{ name: 'Catalogues' }" mini-icon="C" text="Catalogues" />
              <sidenav-item :to="{ name: 'Collections' }" mini-icon="Cs" text="Collections" />
              <sidenav-item :to="{ name: 'Commodities' }" mini-icon="C" text="Commodities" />
            </ul>
          </template>
        </sidenav-collapse>

        <sidenav-collapse collapse-ref="dashboardsExamples" nav-text="Communication"
          :class="getRoute() === 'dashboards' ? 'active' : ''" :show-notification-indicator="unReadMessageCount">
          <span
            :style="{ position: 'relative', color: 'white', top: '8px', width: '20px', height: '20px', borderRadius: '100%', backgroundColor: 'red', fontSize: '12px' }">
            10 </span>
          <template #icon>
            <i class="ni ni-world-2 text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <div class="d-flex flex-row">
                <sidenav-item :to="{ name: 'Chat' }" mini-icon="c" text="Chat" />
               
                <span v-if="unReadMessageCount" class="text-center unreadMessageIndicator text-wrap"> {{
                  unReadMessageCount }} </span>
              </div>
              <sidenav-item :to="{ name: 'BroadcastMessageTemplates' }" mini-icon="B" text="Message Templates" />
              <sidenav-item :to="{ name: 'Mediapost' }" mini-icon="M" text="Social-Media Post" />
            </ul>
          </template>
          
        </sidenav-collapse>

        <sidenav-collapse collapse-ref="dashboardsExamples" nav-text="Applications"
          :class="getRoute() === 'dashboards' ? 'active' : ''">
          <template #icon>
            <i class="fas fa-business-time text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'SalesAssistant' }" mini-icon="S" text="Sales Assistant" />

            </ul>
          </template>
        </sidenav-collapse>

        <!-- <sidenav-collapse collapse-ref="dashboardsExamples" nav-text="Sales Channels"
          :class="getRoute() === 'dashboards' ? 'active' : ''">
          <template #icon>
            <i class="ni ni-settings text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-collapse> -->

        <sidenav-collapse collapse-ref="dashboardsExamples" nav-text="Settings"
          :class="getRoute() === 'dashboards' ? 'active' : ''">
          <template #icon>
            <i class="fas fa-user text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Seller' }" mini-icon="S" text="Seller" />
              <sidenav-item :to="{ name: 'Store' }" mini-icon="S" text="Store" />
              <!-- <sidenav-item :to="{ name: 'StoreLayoutEditor' }" mini-icon="D" text="Store Layout Editor" /> -->
              <sidenav-item :to="{ name: 'User' }" mini-icon="D" text="User" />
              <sidenav-item :to="{ name: 'Integration' }" mini-icon="R" text="Integration" />
            </ul>
          </template>
        </sidenav-collapse>

        <sidenav-collapse collapse-ref="dashboardsExamples" nav-text="Help"
          :class="getRoute() === 'dashboards' ? 'active' : ''">
          <template #icon>
            <i class="ni ni-umbrella-13 text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-item :to="{ name: 'ReportIssue' }" mini-icon="R" text="Report Issue" />
            </ul>
          </template>
        </sidenav-collapse>


        <!--  -->
      </li>
    </ul>
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";

export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    SidenavCollapse,
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
  computed: {
    unReadMessageCount() {
      const count = this.$store.getters["getUnreadMessageCount"] || 0
      return count > 10 ? '10+' : count
    }
  }
};
</script>
<style scoped>
.unreadMessageIndicator {

  position: relative;
  color: white;
  top: 8px;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background-color: red;
  font-size: 12px;
  font-weight: 900
}
</style>