import { IResponseDataModel } from "@/Interfaces/DataModel/ResponseDataModel";
import { mediaPost  } from "@/service/Common/ApiRoutes";
import ApiService         from "@/service/Common/ApiService";
import { MediaPostDataModel } from "@/Interfaces/DataModel/MediaPostDataModel";

function CreateAPost(sellerId: string,data:MediaPostDataModel): Promise<IResponseDataModel<MediaPostDataModel>> {
    const { url, method }: { url: string, method: string } = mediaPost.createApost(sellerId)
    return ApiService.SendRequest(url, method,{data})
    
}

export {CreateAPost}