import {BoardHeadingConfig, stages} from "@/views/configs/orderConfigs";

export const customerEnquiryCardListTableHeading = [
    { field: "", minWidth: 20, maxWidth: 50, checkboxSelection: true },
    {
        field: "Card Title",
        editable: false,
        rowDrag: true,
        cellRenderer: (params: any) =>
            params.data?.title
                ? `<p class="text-xs mt-3 font-weight-bolder text-wrap lh-lg">${params.data.title.slice(0, 500)}</p>`
                : "- ",
        minWidth: 200,
        maxWidth: 400,
    },
    {
        field: "Card Type",
        editable: false,
        rowDrag: true,
        cellRenderer: (params: any) =>
            params.data?.cardType
                ? `<p class="text-xs mt-3 font-weight-bolder text-wrap lh-lg">${params.data.cardType.slice(0, 500)}</p>`
                : "- ",
        minWidth: 200,
        maxWidth: 400,
    },
    {
        field: "Created On",
        editable: false,
        rowDrag: true,
        cellRenderer: (params: any) =>
            params.data?.createdOn
                ? `<p class="text-xs mt-3 font-weight-bolder text-wrap lh-lg">${new Date(params.data?.createdOn).toLocaleDateString('en-GB')}</p>`
                : "- ",
        minWidth: 200,
        maxWidth: 400,
    },

    {
        field: "Customer Name",
        editable: false,
        rowDrag: true,
        cellRenderer: (params: any) =>

            params.data?.customFields[0]?.customerName
                ? `<p class="text-xs mt-3 font-weight-bolder text-wrap lh-lg">${params.data?.customFields[0]?.customerName.slice(0, 500)}</p>`
                : "-",
        minWidth: 200,
        maxWidth: 600,
    },
    {
        field: "Contact Number",
        editable: false,
        rowDrag: true,
        cellRenderer: (params: any) =>

            params.data?.customFields[0]?.phoneNumber
                ? `<p class="text-xs mt-3 font-weight-bolder text-wrap lh-lg">${params.data?.customFields[0]?.phoneNumber}</p>`
                : "-",
        minWidth: 200,
        maxWidth: 600,
    },
    {
        field: "Email",
        editable: false,
        rowDrag: true,
        cellRenderer: (params: any) =>

            params.data?.customFields[0]?.email
                ? `<p class="text-xs mt-3 font-weight-bolder text-wrap lh-lg">${params.data?.customFields[0]?.email}</p>`
                : "-",
        minWidth: 200,
        maxWidth: 600,
    },

    {
        field: "comments",
        lockPosition: "right",
        cellRenderer: (params: any) => `<i class="text-lg fa fa-comment"></i>`, // Ensure correct Font Awesome icon class
        minWidth: 50,
        maxWidth: 80,
    },
    {
        field: "Edit",
        lockPosition: "right",
        cellRenderer: (params: any) => `<i class="text-lg fas fa-user-edit"></i>`, // Ensure correct Font Awesome icon class
        minWidth: 50,
        maxWidth: 80,
    },

    {
        field: "Delete",
        lockPosition: "right",
        cellRenderer: (params: any) => `<ArgonButton data-action="open" class="action-button"><i class="text-lg fas fa-trash"></i></ArgonButton>`, // Ensure correct Font Awesome icon class
        minWidth: 50,
        maxWidth: 80,
    },
];
export const defaultEnquiryCardColDef = {
    flex: 1,
    minWidth: 150,
    sortable: true,
    resizable: true,
};
export const excludeCardFields = [
    'tags',
    'attachments',
    'customFields',
    'cardType'
]

export const stateConfigOfCard =[
    {label:'Active',value:'Active'},{label:"InActive" ,value: "InActive"}]

export enum cardViewConst {
    List = "List",
    Board = "Board",
    TimeLine ='TimeLine',
    Dashboard = 'Dashboard'
}

export const stagesName=[
    "New",
    "Accepted",
    "InProgress",
    "Rejected",
    "ToDo",
    "Done",
    "Stuck",
    "Not Started",
]
interface IBoardHeadingConfig {
    name: string,
    id: string,
    rank: number,
}

export enum enquiryStagesName{
    New="New",
    Accepted="Accepted",
    InProgress="InProgress",
    Rejected="Rejected",
    ToDo="ToDo",
    Done="Done",
    Stuck="Stuck",
    NotStarted="Not Started"
}

export const customerEnquiryBoardHeadingConfig: IBoardHeadingConfig[] = [
    {
        name: enquiryStagesName.New,
        id: 'id_New',
        rank: 1
    },
    {
        name: enquiryStagesName.Accepted,
        id: 'id_Accepted',
        rank: 2
    },
    {
        name: enquiryStagesName.InProgress,
        id: 'id_InProgress',
        rank:3
    },
    {
        name: enquiryStagesName.Rejected,
        id: 'id_Rejected',
        rank:4
    },
    {
        name: enquiryStagesName.ToDo,
        id: 'id_ToDo',
        rank:5

    },
    {
        name: enquiryStagesName.Stuck,
        id: 'id_Stuck',
        rank:7

    },
    {
        name: enquiryStagesName.Done,
        id: 'id_Done',
        rank:6

    },
    {
        name: enquiryStagesName.NotStarted,
        id: 'id_NotStarted',
        rank:7

    }
]

export function getKanabanStageRankByStageName (stageName: stages) {
    return customerEnquiryBoardHeadingConfig.find(stage => stage.name == stageName).rank
}