function defaultState() {
    return {
        iscustomerEnquirySelected: false,
        isCustomerEnquiryCardSelected :false
    }
}

export default {
    
    namespaced: true,
    
    state: defaultState(),
    
    mutations: {
        
        updateIsCustomerEnquirySelected(state: any, payload: boolean) {
            state.iscustomerEnquirySelected = payload
        },
        updateIsCustomerEnquiryCardSelected(state: any, payload: boolean) {
            state.iscustomerEnquiryCardSelected = payload
        },
        
        resetState(state: any) {
            Object.assign(state, defaultState())
            
        },
    },
    
    actions: {
        setIsCustomerEnquirySelected(ctx: any, payload: boolean) {
            ctx.commit("updateIsCustomerEnquirySelected", payload)
        },
        setIsCustomerEnquiryCardSelected(ctx: any, payload: boolean) {
            ctx.commit("updateIsCustomerEnquiryCardSelected", payload)
        }
        
    },
    getters: {
        getIsCustomerEnquirySelected: (state: any) => state.iscustomerEnquirySelected,
        getIsCustomerEnquiryCardSelected: (state: any) => state.iscustomerEnquiryCardSelected,
    },
}
