import { IResponseDataModel } from "@/Interfaces/DataModel/ResponseDataModel";
import { comments } from "@/service/Common/ApiRoutes";
import ApiService from "@/service/Common/ApiService";
import {ICommentsDataModel} from "@/Interfaces/DataModel/CommentsDataModel";

function createAComments(sellerId:string,data:ICommentsDataModel):Promise<IResponseDataModel<ICommentsDataModel>>{
    const {url,method}:{url:string,method:string} = comments.createComments(sellerId);
    return ApiService.SendRequest(url,method,{data});
}
function updateAComments(sellerId:string,data:ICommentsDataModel):Promise<IResponseDataModel<ICommentsDataModel>>{
    const {url,method}:{url:string,method:string} = comments.updateAComments(sellerId,data.commentId);
    return ApiService.SendRequest(url,method,{data});
}

function deleteAComments(sellerId:string,data:ICommentsDataModel):Promise<IResponseDataModel<ICommentsDataModel>>{
    const {url,method}:{url:string,method:string} = comments.deleteAComments(sellerId,data.commentId);
    return ApiService.SendRequest(url,method,{data});
}

function getAComments(sellerId:string,commentId:string):Promise<IResponseDataModel<ICommentsDataModel>>{
    const {url,method}:{url:string,method:string} = comments.getAComments(sellerId,commentId);
    return ApiService.SendRequest(url,method,);
}

function getAllComments(sellerId:string,query):Promise<IResponseDataModel<ICommentsDataModel[]>>{
    const {url,method}:{url:string,method:string} = comments.getAllComments(sellerId);
    return ApiService.SendRequest(url,method,{query});
}

export{
    createAComments,
    getAllComments,
    getAComments,
    deleteAComments,
    updateAComments
}