import { IResponseDataModel } from "@/Interfaces/DataModel/ResponseDataModel";
import { IWorkFlowDataModel } from "@/Interfaces/DataModel/WorkFlowDataModel";
import { WorkFlow } from "@/service/Common/ApiRoutes";
import ApiService from "@/service/Common/ApiService";
import ApiCoreSiloService from "@/service/Common/CoreApiService";
import { data } from "browserslist";
import axios from "axios";

function createAWorkFlow(sellerId: string, data: IWorkFlowDataModel): Promise<IResponseDataModel<IWorkFlowDataModel>> {
    const { url, method }: { url: string, method: string } = WorkFlow.createAWorkFlow(sellerId);
    return ApiService.SendRequest(url, method, { data });
}
function updateAWorkFlow(sellerId: string, data: IWorkFlowDataModel): Promise<IResponseDataModel<IWorkFlowDataModel>> {
    const { url, method }: { url: string, method: string } = WorkFlow.updateAWorkFlow(sellerId, data.workflowId);
    return ApiService.SendRequest(url, method, { data });
}
function getAllWorkFlow(sellerId: string): Promise<IResponseDataModel<IWorkFlowDataModel[]>> {
    const { url, method }: { url: string, method: string } = WorkFlow.getAllWorkFlow(sellerId);
    return ApiService.SendRequest(url, method,);
}
function getAWorkFlow(sellerId: string, workFlowId: string): Promise<IResponseDataModel<IWorkFlowDataModel>> {
    const { url, method }: { url: string, method: string } = WorkFlow.getAWorkFlow(sellerId, workFlowId);
    return ApiService.SendRequest(url, method,);
}
function removeAWorkFlow(sellerId: string, workFlowId: string): Promise<IResponseDataModel<IWorkFlowDataModel>> {
    const { url, method }: { url: string, method: string } = WorkFlow.removeAWorkFlow(sellerId, workFlowId);
    console.log(url, method, "method")
    return ApiService.SendRequest(url, method,);
}

async function downloadTemplate(): Promise<any> {
    const { url, method }: { url: string, method: string } = WorkFlow.downloadWorKFlowTemplate();
    // neeed to add interface
    const response: any = await axios({ url, method });
    return response
}

export { downloadTemplate, createAWorkFlow, updateAWorkFlow, getAllWorkFlow, getAWorkFlow, removeAWorkFlow }