import {createWorkFlowCards, getAllWorkFlowCards, updateWorkFlowCards} from "@/service/apiService/CardsActionService";
import {
    BaseField,
    DatePickerField,
    DynamicFieldsField,
    FileField,
    MultiSelectField,
    RadioField,
    SelectField,
    TextField
} from "@/Interfaces/DataModel/EnquiryDataModel";
import {excludeCardFields, stateConfigOfCard} from "@/views/configs/enquiryCardManagementConfig";
import {IResponseDataModel} from "@/Interfaces/DataModel/ResponseDataModel";
import {ViewModelStatus} from "@/config/dataConfig";
import {concat} from "lodash";


export async function getAllCardsAganistWorkflow(sellerId, workFlowId): Promise<IResponseDataModel<any>> {
    const response = await getAllWorkFlowCards(sellerId, workFlowId);
    return response
}

type FormField =
    | TextField
    | SelectField
    | DatePickerField
    | RadioField
    | MultiSelectField
    | FileField
    | DynamicFieldsField;
export const cardSchemaToFormFields = (schema: any): FormField[] => {
    return Object.keys(schema.properties).filter(key => !excludeCardFields.includes(key)).map(key => {
        const field = schema.properties[key];
        const baseField: BaseField = {
            name: key,
            type: field.uiType,
            placeholder: field.placeholder || key,
            validate: field.required ? value => {
                if (!value) return `${key} is required`;
            } : undefined
        };

        switch (field.uiType) {
            case 'text':
            case 'textarea':
                return {...baseField, type: field.uiType, rows: field.rows} as TextField;

            case 'select':
                return {
                    ...baseField,
                    type: 'select',
                    optionsUrl: field.optionsUrl,
                    optionsMapping: field.optionsMapping
                } as SelectField;

            case 'datepicker':
                return {...baseField, type: 'datepicker'} as DatePickerField;

            case 'radio':
                return {
                    ...baseField,
                    type: 'radio',
                    options: field.options
                } as RadioField;

            case 'multiselect':
                return {
                    ...baseField,
                    type: 'multiselect',
                    optionsUrl: field.optionsUrl,
                    optionsMapping: field.optionsMapping
                } as MultiSelectField;

            case 'file':
                return {
                    ...baseField,
                    type: 'file',
                    multiple: field.multiple
                } as FileField;

            case 'dynamicFields':
                return {...baseField, type: 'dynamicFields'} as DynamicFieldsField;

            default:
                throw new Error(`Unknown uiType: ${field.uiType}`);
        }
    });
}

export function reAssignOptionValue(template, userDatModel) {
    const mapUserDataToOptions = (users) => users.map(user => ({
        value: user.userId,
        label: user.userName
    }));
    const updatedTemplate = {
        ...template,
        properties: {
            ...template.properties,
            participants: {
                ...template.properties?.participants,
                optionsMapping: mapUserDataToOptions(userDatModel)
            },
            currentAssignee: {
                ...template.properties?.currentAssignee,
                optionsMapping: mapUserDataToOptions(userDatModel)
            },
            state: {
                ...template.properties?.state,
                optionsMapping: stateConfigOfCard
            }
        }
    };
    return updatedTemplate
}

export function convertToCardDataModel(dataModel,dynamicValues) {
    //todo
    // console.log(convertArrayToObject(dataModel.customFields), "convertArrayToObject(dataModel.customFields)")
    // const data = {
    //     title: dataModel.title,
    //     description: dataModel.description,
    //     currentAssignee: dataModel.currentAssignee,
    //     participants: [dataModel.participants],
    //     duedata: 0,
    //     priority: dataModel.priority,
    //     state: dataModel.state,
    //     workflowId: workflow.workflowId,
    //     sellerId: workflow.sellerId,
    //     regionId: workflow.regionId,
    //     userId: workflow.userId,
    //     cardType: dataModel.cardType,
    //     CurrentStageName: dataModel.CurrentStageName,
    //     metadata: convertArrayToObject(dataModel.customFields)
    // }
    const data ={
        ...dataModel,
        metadata:convertArrayToObject(dynamicValues.customFields),
        participants:concat([],dataModel.participants)
    }
    return data
}

function convertArrayToObject(array: Array<Record<string, string>>): Record<string, string> {
    return array.reduce((accumulator, current) => {
        for (const [key, value] of Object.entries(current)) {
            accumulator[key] = value;
        }
        return accumulator;
    }, {} as Record<string, string>);
}

export function convertCardDataModelToViewModel(DataModel: any) {
    const dynamicFields = convertObjectToArray(DataModel.metadata)
    const {metadata, ...restOfDataModel} = DataModel;
    return {
        ...restOfDataModel,
        saved: DataModel.workflowId ? ViewModelStatus.Saved : ViewModelStatus.New,
        customFields: [DataModel.metadata],
        dynamicFields: dynamicFields,
    };
}

export function convertObjectToArray(obj: Record<string, string>) {
    const keysToExclude = ["customerName", "phoneNumber", "email", "customerId"];
    const filteredArray = Object.entries(obj)
        .filter(([key]) => !keysToExclude.includes(key)) // Exclude specified keys
        .map(([key, value]) => ({
            name: key, value: obj[key]
        }));
    return filteredArray
}

export function ToEmptyWorkFlowCardListViewModel(sellerId: string, userId: string, regionId: string,workflowId:string, dataModel: any) {
    return {
        sellerId,
        userId,
        regionId,
        ...dataModel,
        cardId: "",
        workflowId,
        currentStageId:""
    };
}

export async function createOrUpdateWorkFlowCards(dataModel){
    if(dataModel.cardId){
        return await updateWorkFlowCards(dataModel.sellerId,dataModel.workflowId,dataModel)
    }else{
        return await createWorkFlowCards(dataModel.sellerId,dataModel.workflowId,dataModel)
    }
}