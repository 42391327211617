import {createAComments, deleteAComments, getAllComments, updateAComments} from "@/service/apiService/CommentsService";
import {ICommentsDataModel, ICommentsViewModel} from "@/Interfaces/DataModel/CommentsDataModel";
import {ViewModelStatus} from "@/config/dataConfig";
import {IResponseDataModel} from "@/Interfaces/DataModel/ResponseDataModel";
import {uploadCommentImage} from "@/service/fileService";


export async function getAllCommentsToViewModel(sellerId:string,query:any):Promise<ICommentsViewModel[]>{
    const resp = await getAllComments(sellerId,query) ;
    const commentsViewModel:ICommentsViewModel[] = resp.result?resp.result.map((comment) =>convertToViewModel(comment)):[];
    return commentsViewModel;
}

function convertToViewModel(dataModel:any):ICommentsViewModel{
    const viewModel = dataModel
    viewModel.saved = ViewModelStatus.New
    return dataModel;
}

export async function createOrUpdateComments(sellerId:string,commentsDataModel:ICommentsViewModel):Promise<IResponseDataModel<ICommentsDataModel>>{
    if(commentsDataModel.commentId){
      return  await updateAComments(sellerId,commentsDataModel)
    }else{
       return await createAComments(sellerId,commentsDataModel)
    }
}

export async function deleteComments(sellerId:string,commentDataModel:ICommentsViewModel):Promise<IResponseDataModel<any>>{
    return await deleteAComments(sellerId,commentDataModel)
}

export async function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
        if (!(blob instanceof Blob)) {
            return reject(new TypeError("Argument must be a Blob object"));
        }
        const fileReader = new FileReader();
        fileReader.readAsDataURL(blob);

        fileReader.onloadend = function() {
            resolve(fileReader.result); // Here is the base64 string
        };

        fileReader.onerror = function(error) {
            reject(error);
        };
    });
}

export async function replaceCommentsImageToUrl(originalSrc,sellerId){

    const response =  await fetch(originalSrc)
    const blob = await response.blob();
    const fileconfig = {
        uploadedFor: 'image',
        collectionType: "media",
        secureLevelCode: "Public",
        sellerId: sellerId,
        files: blob,
    };
    const newImageUrl = await uploadCommentImage(fileconfig);
    return newImageUrl
}