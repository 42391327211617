import { GetAFile, UploadAFile } from "@/service/apiService/FileService"


async function uploadImage(config: any) {

    const imgCollections = []
    const resp = await uploadFiles(config)
    if (resp.isSuccess && resp.result) {

        for (const collectionId in resp.result.metaData)
            imgCollections.push({ id: collectionId, url: resp.result.metaData[collectionId].uri })
    }

    return imgCollections
}
async function uploadCommentImage(config: any) {

    const imgCollections = []
    const resp = await uploadFiles(config)
    if (resp.isSuccess && resp.result) {

        for (const collectionId in resp.result.metaData)
            imgCollections.push({ id: collectionId, url: resp.result.metaData[collectionId].uri + `?type:${resp.result.files[0].contentType}`  })
    }

    return imgCollections
}

async function uploadFiles(config: any) {
    const data = new FormData()

    data.append('UploadedFor', config.uploadedFor)
    data.append('CollectionType', config.collectionType)
    data.append('SecureLevelCode', config.secureLevelCode)
    data.append('sellerId', config.sellerId)

    if (Array.isArray(config.files)) {
        for (const file of config.files) {
            data.append('files', file)
        }
    } else {
        data.append('files', config.files)
    }

    return await UploadAFile(config.sellerId, data)
}

async function setFileUrl(imgUrl: string, sellerId: string, type) {
    imgUrl = imgUrl.startsWith('/') ? imgUrl.slice(1) : imgUrl
    type = type || 'image/jpeg'
    const file = await GetAFile(sellerId, imgUrl)
    const blob = new Blob([file], { type });
    return URL.createObjectURL(blob);
}

async function setCommentFileUrl(imgUrl: string, sellerId: string, filetype:any) {
    imgUrl = imgUrl.startsWith('/') ? imgUrl.slice(1) : imgUrl
    const file = await GetAFile(sellerId, imgUrl)
    const blob = new Blob([file],{ type:filetype } );
    return blob
}


async function setImgUrl(urlCollection: string[] | string, sellerId: string, type?: string) {
    const blobUrlPrefix = 'http://{host}'
    if (!urlCollection) {
        return ''
    } else if (Array.isArray(urlCollection)) {
        return urlCollection[0].split(blobUrlPrefix).slice(1).map(async url =>  await setFileUrl(url, sellerId, type))
    } else {
        return  await setFileUrl(urlCollection.split(blobUrlPrefix)[1], sellerId, type)
    }

}
export async function setCommentImgUrl(urlCollection: string, sellerId: string, type?: string) {
    const blobUrlPrefix = 'http://{host}'
    const splitImageType = urlCollection.split("?type")
    const updatefiletype =splitImageType[1].split("?type:")
    if (!urlCollection) {
        return ''
    }  else {
        return  await setCommentFileUrl(splitImageType[0].split(blobUrlPrefix)[1], sellerId, updatefiletype[0].replace(/:/g, ""))
    }

}


export { uploadImage, setImgUrl, uploadFiles ,setCommentFileUrl,uploadCommentImage}