export function ToEmptyInitStoreActiveViewModel() {
    const dataModel = {
        storeId : "" ,
        storeName : ""
    }
    return dataModel
}

export function ToEmptyInitCatalogueActiveViewModel() {
    return {
        catalogueId : "",
        catalogueName : "",
        commoditiesCount : 0,
        daysLeft : 0 ,
        orderCount : 0,
        storeCount : 0
    }
}