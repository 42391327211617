<!-- App.vue -->
<template>
  <div>

    <Modal :visible="visible" @closeWorkFlowModal="closeworkFlowModal">
      <DynamicForm
        :dynamicschema="workflowschemamodel"
        :customer-enquiry-selected="customerEnquirySelected"
        :user-data-model="userDataModel"
        @updateWorkFlow="updateWorkFlow"
        :work-flow-active-model="workFlowActiveModel"
      />
    </Modal>
  </div>
</template>

<script setup>
import { defineProps, onMounted, ref, defineEmits } from "vue";
import DynamicForm from "@/components/custom/customerEnqiry/dynamicFormComponent.vue";
import Modal from "@/components/custom/customerEnqiry/dynamicModalView.vue";
const emit = defineEmits(["closeworkFlowModal", "updateOrCreateWorkFlow"]);
const props = defineProps({
  workflowschemamodel: {
    type: Array,
  },
  visible: Boolean,
  customerEnquirySelected: Boolean,
  userDataModel: {
    type: Object,
    required: true,
  },
  workFlowActiveModel: {
    type: Object,
    required: true,
  },
});
function closeworkFlowModal() {
  emit("closeworkFlowModal", false);
}
function updateWorkFlow(values) {
  emit("updateOrCreateWorkFlow", values);
}
</script>
