import { ITemplateViewModel } from "@/Interfaces/DataModel/TemplateDataModel"
import { MediaPostDataModel } from "@/Interfaces/DataModel/MediaPostDataModel";


export function ToEmptyMessageTemplateListViewModel(sellerId,regionId,userId):ITemplateViewModel{
    const emptyModel={
        sellerId,
        regionId,
        userId,
        templateId:"",
        templateName:"",
        body:"",
        category:"",
        status:"",
        broadcastName:"", footer:"", button:{name:"",value:""}, lastUpdate:"",
        saved:'NEW'
    }
    return emptyModel
}

export function ToEmptyMediaPostActiveViewModel(sellerId:string,regionId:string,userId:string):MediaPostDataModel{
    const emptyModel={
        sellerId,
        regionId,
        storeId:"",
        userId,
        accessIds:{},
        accessCredentials:{},
        accessPointName:[],
        appType:"",
        postType:"",
        message:"",
        fileType:null,
        file:null,
        link:"",
        url:""
    }
    return emptyModel
}